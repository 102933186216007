
export default class Ymap {
	constructor(options) {

		this._polyfill();
		Object.assign(this._options = {}, this._default(), options);
		this.map = null;
		this.objectManager = null;
		this.objectCollection = [];
		this.objectFilter = null;

		if (document.readyState === 'loading') {
			document.addEventListener('DOMContentLoaded', () => {
				this.init();
			});
		} else {
			this.init();
		}
	}

	_polyfill() {

		//assign
		if (typeof Object.assign != 'function') {
			Object.assign = function (target, varArgs) { // .length of function is 2
				'use strict';
				if (target == null) { // TypeError if undefined or null
					throw new TypeError('Cannot convert undefined or null to object');
				}

				var to = Object(target);

				for (var index = 1; index < arguments.length; index++) {
					var nextSource = arguments[index];

					if (nextSource != null) { // Skip over if undefined or null
						for (var nextKey in nextSource) {
							// Avoid bugs when hasOwnProperty is shadowed
							if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
								to[nextKey] = nextSource[nextKey];
							}
						}
					}
				}
				return to;
			};
		}

		if (!Array.isArray) {
			Array.isArray = function (arg) {
				return Object.prototype.toString.call(arg) === '[object Array]';
			};
		}

		if (!Array.from) {
			Array.from = (function () {
				let toStr = Object.prototype.toString,
					isCallable = (fn) => typeof fn === 'function' || toStr.call(fn) === '[object Function]',
					toInteger = (value) => {
						let number = Number(value);
						if (isNaN(number)) {
							return 0;
						}
						if (number === 0 || !isFinite(number)) {
							return number;
						}
						return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
					},
					maxSafeInteger = Math.pow(2, 53) - 1,
					toLength = function (value) {
						let len = toInteger(value);
						return Math.min(Math.max(len, 0), maxSafeInteger);
					};

				return function from(arrayLike) {
					let C = this,
						items = Object(arrayLike);
					if (arrayLike == null) throw new TypeError('Array.from requires an array-like object - not null or undefined');

					let mapFn = arguments[1];

					if (typeof mapFn !== 'undefined') {
						mapFn = arguments.length > 1 ? arguments[1] : void undefined;
						if (!isCallable(mapFn)) throw new TypeError('Array.from: when provided, the second argument must be a function');
						if (arguments.length > 2) T = arguments[2];
					}

					let len = toLength(items.length),
						A = isCallable(C) ? Object(new C(len)) : new Array(len),
						k = 0,
						kValue;

					while (k < len) {
						kValue = items[k];
						if (mapFn) {
							A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
						} else {
							A[k] = kValue;
						}
						k += 1;
					}
					A.length = len;
					return A;
				};
			}());
		}
	}

	_default() {
		return {
			apiVersion: '2.1',
			container: '#map',
			center: [51.524381, -0.142804],
			native: true,
			removeObjectsOnBoundsChange: false,
			duration: 0,
			zoom: 10,
			controls: ['default'],	// ['default', 'fullscreenControl', 'geolocationControl', 'routeEditor', 'rulerControl', 'searchControl', 'trafficControl', 'typeSelector', 'zoomControl']  https://tech.yandex.ru/maps/doc/jsapi/2.1/ref/reference/control.Manager-docpage/#add-param-control
			behaviors: ['default'],	// ['default','drag','scrollZoom','dblClickZoom','multiTouch','rightMouseButtonMagnifier','leftMouseButtonMagnifier','ruler','routeEditor'] https://tech.yandex.ru/maps/doc/jsapi/2.1/ref/reference/map.behavior.Manager-docpage/#param-behaviors
			minZoom: 0,
			maxZoom: 23,
			objectManagerOptions: {
				clusterize: true,
				gridSize: 128,
				viewportMargin: 0
			},

			objectCollectionParser: (obj) => {
				return {
					geometry: {
						coordinates: obj.geometry.coordinates
					}
				}
			},

			onInit: (map) => {
			},
			onBoundsChange: (map) => {
			}
		}
	}

	_getScript(url, callback) {
		let script = document.createElement('script'),
			prior = document.getElementsByTagName('script')[0];
		script.async = 1;
		script.onload = script.onreadystatechange = function (_, isAbort) {
			if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
				script.onload = script.onreadystatechange = null;
				script = undefined;

				if (!isAbort) {
					if (callback) callback();
				}
			}
		};
		script.src = url;
		prior.parentNode.insertBefore(script, prior);
	}

	_storage() {
		return {
			src: 'https://api-maps.yandex.ru/' + this._default().apiVersion + '/?lang=ru_RU'
		}
	}

	_initApi() {
		this._getScript(this._storage().src, () => {
			ymaps.ready(() => {
				this._initApiCallback()
			});
		});
	}

	_initApiCallback() {

		let container = document.querySelectorAll(this._options.container);

		if (container.length === 0) {
			console.error('container must be specified');
			return false;
		}

		this.map = new ymaps.Map(container[0], {
			center: this._options.center,
			zoom: this._options.zoom || 10,
			controls: this._options.controls,
			behaviors: this._options.behaviors
		}, {
			autoFitToViewport: 'always',
			minZoom: this._options.minZoom || 0,
			maxZoom: this._options.maxZoom || 23
		});

		this.objectManager = new ymaps.ObjectManager(this._options.objectManagerOptions);

		this.map.geoObjects.add(this.objectManager);

		this._options.onInit(this.map);


		this.map.events.add('boundschange', () => {
			if (!this._options.native) {
				this.clearObjects();
				this.objectManager.add(this.shownObjects());
			}
			this._options.onBoundsChange(this.map);
		});
	}

	// public

	shownObjects() {

		let objShown = [],
			i = 0,
			screenCoord = this.map.getBounds();

		for (i; i < this.objectCollection.length; i++) {
			let objCoord = this.objectCollection[i].geometry.coordinates,
				filter = (this.objectFilter && typeof this.objectFilter === 'function') ? this.objectFilter(this.objectCollection[i]) : true;

			if (
				objCoord[0] >= screenCoord[0][0] &&
				objCoord[0] <= screenCoord[1][0] &&
				objCoord[1] >= screenCoord[0][1] &&
				objCoord[1] <= screenCoord[1][1] &&
				filter
			) {
				objShown.push(this.objectCollection[i]);
			}
		}

		return objShown;
	}

	setFilter(func) {
		this.objectFilter = func || null;

		if (this._options.native) {
			this.objectManager.setFilter(this.objectFilter);
		} else {
			this.clearObjects();
			this.objectManager.add(this.shownObjects());
		}
	}

	init(options) {
		if (!this.map) {
			Object.assign(this._options, options);
			this._initApi();
		} else {
			console.warn('map is initialized');
		}
		return this.map;
	}

	destroy() {
		if (this.map) {
			this.clearObjects();
			this.map.destroy();
			this.map = null;
			this.objectManager = null;
		}
		return this.map;
	}

	addObjects(arr) {

		if (this.objectCollection.length) {
			console.warn('to add new objects use updateObjects(arr) method');
			return false;
		}

		if (!arr || !(arr && arr.length)) {
			console.warn('No objects to adding');
			this.clearObjects();
			return false;
		}

		this.objectCollection = [];

		for (let i = 0; i < arr.length; i++) {

			let object = this._options.objectCollectionParser(arr[i]);
			object.type = 'Feature';
			object.id = object.id || i;
			object.geometry.type = 'Point';

			this.objectCollection.push(object);
		}

		if (this._options.native) {
			this.objectManager.add(this.objectCollection);
		} else {
			this.objectManager.add(this.shownObjects());
		}

	}

	clearObjects() {
		if (this.objectManager) {
			this.objectManager.objects.removeAll();
			if (this._options.removeObjectsOnBoundsChange) this.objectCollection = [];
		}
	}

	updateObjects(arr) {
		if (!this.objectCollection.length) {
			this.addObjects(arr);
		} else {
			this.clearObjects();
			this.addObjects(arr);
		}
	}

	setCenter(coord, zoom) {

		let opts = {},
			z = zoom || this.map.getZoom();

		opts.duration = this._options.duration;

		if (coord.length && Array.isArray(coord[1])) {
			this.map.setBounds(coord, opts);
		} else if (Array.isArray(coord)) {
			this.map.setCenter(coord, z, opts);
		}
	}
}
