'use strict';

export default class Popup {
	constructor(options) {

		this._polyfill();

		Object.assign(this._options = {}, this._default(), options);
		if (document.readyState === 'loading') {
			document.addEventListener('DOMContentLoaded', () => {
				this._init();
			});
		} else {
			this._init();
		}
	}

	_default() {
		return {
			bodyClass: false,
			fixedOnIos: false,
			closePopupOnEsc: false,
			closePopupOnOverlay: false,
			autoCloseOtherPopups: true,
			dataPopupOpen: 'data-popup-open',
			dataPopupClose: 'data-popup-close',
			dataPopupRemove: 'data-popup-remove',
			template: `<div class="{overlay}"></div>
						<div class="{inner}">
							<div class="{layout}">
								<div class="{close}"></div>
								<div class="{content}"></div>
							</div>
						</div>`,
			className: {
				wrap: 'popup',
				inner: 'popup__inner',
				layout: 'popup__layout',
				close: 'popup__close',
				content: 'popup__content',
				overlay: 'popup__overlay',
				openPopup: 'is-open'
			},
			onPopupOpen: function () {
			},
			onPopupClose: function () {
			},
			onPopupRemove: function () {
			}
		}
	}

	_iosDetect() {
		if (/iPad|iPhone|iPod/.test(navigator.userAgent) && this._options.fixedOnIos) {
			this._options.isIos = true;
		}
	}

	_templateToHTML(template, data) {
		return template ? template.replace(/\{([^\}]+)\}/g, function (value, key) {
			return key in data ? data[key] : value;
		}).replace(/&([^\=]+)\=\{([^\}]+)\}/g, '') : '';
	}

	_sbw(){
		let scrollDiv = document.createElement('div'), sbw;
		scrollDiv.className = 'scroll_bar_measure';
		scrollDiv.style.width = '100px';
		scrollDiv.style.height = '100px';
		scrollDiv.style.overflow = 'scroll';
		scrollDiv.style.position = 'absolute';
		scrollDiv.style.top = '-9999px';
		document.body.appendChild(scrollDiv);
		sbw = scrollDiv.offsetWidth - scrollDiv.clientWidth;
		document.body.removeChild(scrollDiv);
		//-console.info(sbw);
		return sbw;
	}

	create(pop, html, callback) {

		if (document.querySelectorAll(pop).length) {
			console.warn(`${pop} can not be created because there is already`);
			return false;
		}

		let cb = callback;

		let pref = pop.indexOf('#') === 0 ? 'id' : 'className',
			popup = document.createElement('div');
		popup.innerHTML = this._templateToHTML(this._options.template, this._options.className);
		popup[pref] = pop.replace(/^[\.#]/, '');
		popup.classList.add(this._options.className.wrap);

		if (html && typeof html === 'function') {
			cb = html;
		} else if (html && typeof html === 'string') {
			popup.getElementsByClassName(this._options.className.content)[0].innerHTML = html;
		}

		document.body.appendChild(popup);

		if (cb) cb(popup);

		return popup;
	}

	open(pop, html, callback) {


		let cb = callback;

		if (this._options.autoCloseOtherPopups && document.getElementsByClassName(this._options.className.openPopup).length) {
			this.close();
		}

		let popup = typeof pop === 'string' ? document.querySelector(pop) : ( typeof pop === 'object' ? pop : false);

		if (!popup) {
			console.warn(`${pop} can not be opened because it does not exist`);
			return false;
		} else {
			popup.classList.add(this._options.className.openPopup);

			if (html && typeof html === 'function') {
				cb = html;
			} else if (html && typeof html === 'string') {
				popup.getElementsByClassName(this._options.className.content)[0].innerHTML = html;
			}
		}
		document.body.style.overflow = 'hidden';
		document.body.style.paddingRight = this._sbw() + 'px';
		if (this._options.bodyClass) document.body.classList.add(this._options.bodyClass);
		this._options.popupIsOpen = true;

		if (this._options.isIos) {
			document.body.style.top = -pageYOffset + 'px';
			document.body.style.position = 'fixed';
		}

		if (this._options.onPopupOpen && typeof this._options.onPopupOpen === 'function') this._options.onPopupOpen(popup);

		if (cb) cb(popup);

		return popup;
	}

	close(pop, callback) {
		let popup = typeof pop === 'string' ? document.querySelectorAll(pop) : ( typeof pop === 'object' ? pop : document.getElementsByClassName(this._options.className.wrap) );

		if (popup.classList !== undefined) {
			popup.classList.remove(this._options.className.openPopup);
		} else {
			Array.from(popup).forEach(popup => {
				popup.classList.remove(this._options.className.openPopup);
			});
		}

		document.body.style.overflow = '';
		document.body.style.paddingRight = '';
		if (this._options.bodyClass) document.body.classList.remove(this._options.bodyClass);
		this._options.popupIsOpen = false;

		if (this._options.isIos) {
			let st = Math.abs(document.body.style.top.replace('px', ''));
			document.body.style.top = '';
			document.body.style.position = '';
			window.scrollTo(0, st);
		}

		if (this._options.onPopupClose && typeof this._options.onPopupClose === 'function') this._options.onPopupClose(popup);

		if (callback) callback(popup);

		return popup;
	}

	remove(pop, callback) {
		let popup = typeof pop === 'string' ? document.querySelectorAll(pop) : ( typeof pop === 'object' ? pop : ( document.getElementsByClassName(this._options.className.wrap) ) );

		Array.from(popup).forEach(popup => {
			document.body.removeChild(popup)
		});

		if (this._options.onPopupRemove && typeof this._options.onPopupRemove === 'function') this._options.onPopupRemove(popup);

		if (callback) callback();
	}

	_init() {

		this._iosDetect();

		document.body.addEventListener('click', (e) => {

			if (e.target.getAttribute(this._options.dataPopupOpen)) {
				e.preventDefault();
				this.open(e.target.getAttribute(this._options.dataPopupOpen));
			}

			if (e.target.getAttribute(this._options.dataPopupClose)) {
				e.preventDefault();
				this.close(e.target.getAttribute(this._options.dataPopupClose));
			}

			if (e.target.getAttribute(this._options.dataPopupRemove)) {
				e.preventDefault();
				this.remove(e.target.getAttribute(this._options.dataPopupRemove));
			}

			let popup = e.target.closest('.' + this._options.className.wrap);

			if (popup) {

				if (e.target.classList.contains(this._options.className.close) || ( this._options.closePopupOnOverlay && !e.target.closest('.' + this._options.className.layout) )) this.close(popup);

			}

		});

		document.body.addEventListener('keyup', (e) => {
			if (this._options.closePopupOnEsc && e.keyCode === 27) {
				this.close();
			}
		});

	}

	_polyfill() {
		//matches
		if (!Element.prototype.matches) {
			Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.webkitMatchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector;
		}

		//closest
		if (!Element.prototype.closest) {
			Element.prototype.closest = function (css) {
				let node = this;
				while (node) {
					if (node.matches(css)) return node;
					else node = node.parentElement;
				}
				return null;
			};
		}

		//assign
		if (!Object.assign) {
			Object.defineProperty(Object, 'assign', {
				enumerable: false,
				configurable: true,
				writable: true,
				value: function (target, firstSource) {
					'use strict';
					if (target === undefined || target === null) {
						throw new TypeError('Cannot convert first argument to object');
					}

					let to = Object(target);
					for (let i = 1; i < arguments.length; i++) {
						let nextSource = arguments[i];
						if (nextSource === undefined || nextSource === null) {
							continue;
						}

						let keysArray = Object.keys(Object(nextSource));
						for (let nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
							let nextKey = keysArray[nextIndex],
								desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
							if (desc !== undefined && desc.enumerable) {
								to[nextKey] = nextSource[nextKey];
							}
						}
					}
					return to;
				}
			});
		}

		if (!Array.from) {
			Array.from = (function () {
				let toStr = Object.prototype.toString,
					isCallable = (fn) => typeof fn === 'function' || toStr.call(fn) === '[object Function]',
					toInteger = (value) => {
						let number = Number(value);
						if (isNaN(number)) {
							return 0;
						}
						if (number === 0 || !isFinite(number)) {
							return number;
						}
						return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
					},
					maxSafeInteger = Math.pow(2, 53) - 1,
					toLength = function (value) {
						let len = toInteger(value);
						return Math.min(Math.max(len, 0), maxSafeInteger);
					};

				return function from(arrayLike) {
					let C = this,
						items = Object(arrayLike);
					if (arrayLike === null) throw new TypeError('Array.from requires an array-like object - not null or undefined');

					let mapFn = arguments[1];

					if (typeof mapFn !== 'undefined') {
						mapFn = arguments.length > 1 ? arguments[1] : void undefined;
						if (!isCallable(mapFn)) throw new TypeError('Array.from: when provided, the second argument must be a function');
						if (arguments.length > 2) T = arguments[2];
					}

					let len = toLength(items.length),
						A = isCallable(C) ? Object(new C(len)) : new Array(len),
						k = 0,
						kValue;

					while (k < len) {
						kValue = items[k];
						if (mapFn) {
							A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
						} else {
							A[k] = kValue;
						}
						k += 1;
					}
					A.length = len;
					return A;
				};
			}());
		}

	}

}
