'use strict';

export default class Viewport {
	constructor(options) {

		this.currentPoint = null;
		this.winWith = null;

		if (document.readyState === 'loading') {
			document.addEventListener('DOMContentLoaded', () => {
				this.__init(options);
			});
		} else {
			this.__init(options);
		}
	}


	__init(options){

		let points = options;
		if ( points ){
			points['Infinity'] = null;
			let sbw = 0;

			this.winWith = window.innerWidth + sbw;
			this.__checkCurrentViewport(points);

			window.onresize = ()=>{
				this.winWith = window.innerWidth + sbw;
				this.__checkCurrentViewport(points);
			}
		}
	}

	__checkCurrentViewport(obj){

		let point = 0, callback = null;

		for (let key in obj) {

			if ( key > this.winWith ){
				if ( point !== this.currentPoint ) {
					this.currentPoint = point;
					callback();
				}
				return false;
			}
			point = key; callback = obj[key];
		}

	}

	__scrollBarWidth(){
		let scrollDiv = document.createElement('div'), sbw;
		scrollDiv.className = 'scroll_bar_measure';
		$(scrollDiv).css({
			width: '100px',
			height: '100px',
			overflow: 'scroll',
			position: 'absolute',
			top: '-9999px'
		});
		document.body.appendChild(scrollDiv);
		sbw = scrollDiv.offsetWidth - scrollDiv.clientWidth;
		document.body.removeChild(scrollDiv);
		return sbw;
	}
}
