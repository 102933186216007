import $ from 'jquery';
import Popup from '../modules/module.popup'
import Form from '../modules/module.validate'
import ViewPort from '../modules/module.viewport'
import Yamap from '../modules/module.map'
import ScrollBar from '../modules/module.scrollbar'
import noUiSlider from 'nouislider'
import StickySidebar from 'sticky-sidebar'
import Inputmask from 'inputmask'
import autosize from 'autosize'
// import select2 from 'select2'
import 'slick-carousel'
require('malihu-custom-scrollbar-plugin')($);

// if (typeof select2 === 'function') {
// 	select2(window, $);
// }


String.prototype.discharge = function(mark) {
	let x = this.split('.'),x1 = x[0],x2 = x.length > 1 ? '.' + x[1] : '',rgx = /(\d+)(\d{3})/;
	while (rgx.test(x1)) { x1 = x1.replace(rgx, '$1' + (mark ? mark : ' ') + '$2'); }
	return x1 + x2;
};

Number.prototype.discharge = function(mark) {
	let val = this + '',x = val.split('.'),x1 = x[0],x2 = x.length > 1 ? '.' + x[1] : '',rgx = /(\d+)(\d{3})/;
	while (rgx.test(x1)) { x1 = x1.replace(rgx, '$1' + (mark ? mark : ' ') + '$2'); }
	return x1 + x2;
};

window.app = window.app || {};


$(function () {

	//**/

	let $b = $('body');

	window.app.scrollbar = new ScrollBar({
		customElement: '.js-scroll-bar',
		autoHide: false,
		wrapContent : false
	});

	window.app.scrollbar.init();


	$('textarea').each(function(){
		autosize(this);
	}).on('autosize:resized', function(){
		$(window).trigger('resize');
	});

	window.app.popup = new Popup({
		onPopupOpen: popup => {
			let $popup = $(popup),
				$list = $popup.find('.popup__product-image-list')
				;

			if ($list.length){

				$popup.find('.popup__product-image-list').each(function () {
					let $slider = $(this),
						options = {
							dots: false,
							infinite: true,
							swipeToSlide: true,
							arrows: false,
							speed: 300,
							slidesToScroll: 1,
							slidesToShow: 1
						}
					;

					if ( $list.length > 1){
						options.asNavFor = $list.not($slider).eq(0)
					}

					if ( $slider.closest('.popup__product-image-preview').length ){
						options.slidesToShow = 3;
						options.arrows = true;
						options.focusOnSelect = true;

						if ($slider.closest('.popup__product-image_gallery').length ){
							options.arrows = false;
							options.slidesToShow = 7;
						}

					} else if ($slider.closest('.popup__product-image-main').length ){
						options.fade= true;

						if ($slider.closest('.popup__product-image_gallery').length ){
							options.arrows = true;
						}
					}

					$slider
						.on('init', function (event, slick) {})
						.slick(options);
				});

			}
		}
	});


	const form = new Form();

	if ( $('.cart__aside').length ){
		new StickySidebar('.cart__aside', {
			topSpacing: 20,
			bottomSpacing: 20,
			containerSelector: '.cart',
			innerWrapperSelector: '.cart__quick'
		});
	}

	if ( $('.lk__aside').length ){
		new StickySidebar('.lk__aside', {
			topSpacing: 20,
			bottomSpacing: 20,
			containerSelector: '.lk',
			innerWrapperSelector: '.lk__menu'
		});
	}

	// $('select').each(function () {
	// 	let $t = $(this);
	//
	// 	$t.select2({
	// 		mouseWheel: true,
	// 		width: false,
	// 		language: {
	// 			noResults: function () {
	// 				return 'Поиск не дал результатов';
	// 			}
	// 		},
	// 		/*templateResult: (state)=>{
	// 			console.info(state.element);
	//
	// 			let html,
	// 				dataWrapTag = $(state.element).attr('data-wrap-tag'),
	// 				dataIco = $(state.element).attr('data-icon'),
	// 				img = dataIco ? '<img class="select2-icon" src="' + dataIco + '">' : '',
	// 				wrapTagStart = dataWrapTag ? '<'+dataWrapTag+'>' : '',
	// 				wrapTagEnd = dataWrapTag ? '</'+dataWrapTag+'>' : ''
	// 			;
	//
	// 			html = '<div>' + wrapTagStart + img + state.text +  wrapTagEnd + '</div>';
	// 			return $(html);
	// 		}*/
	// 	}).on('select2:open', (e)=>{
	// 		// $(".select2-search--dropdown .select2-search__field").attr("placeholder", "Поиск");
	// 		//
	// 		// $(".select2-results ul.select2-results__options").unbind("mousewheel");
	// 		//
	// 		// setTimeout(()=>{window.app.scrollbar.init('.select2-dropdown');}, 100);
	// 		// that.scroll();
	//
	// 		$(".select2-results").unbind("mousewheel");
	// 		// setTimeout(()=>{
	// 		// 	window.app.scrollbar.init('.select2-results');
	// 		// },5000);
	//
	//
	//
	// 	});
	//
	// });

	$('input[type = tel]').each(function () {
		let inputmask = new Inputmask({
			mask: '+7 (999) 999-99-99',
			showMaskOnHover: false,
			onincomplete: function() {
				this.value = '';
				$(this).closest('.form__field').removeClass('f-filled');
			}
		});
		inputmask.mask($(this)[0]);
	});


	$('[data-mask]').each(function () {
		let inputmask = new Inputmask({
			mask: $(this).attr('data-mask'),
			showMaskOnHover: false,
			onincomplete: function() {
				this.value = '';
			}
		});
		inputmask.mask($(this)[0]);
	});



	$('.filter__range').each(function () {
		let $range = $(this),
			$line = $range.find('.filter__range-line'),
			min = +$range.attr('data-value-min'),
			max = +$range.attr('data-value-max')
			;

		if (min === 'NaN' || max === 'NuN' || min === 'undefined' || max === 'undefined' ){
			console.error('Добавьте минимальное и максимаьлное значение фильтра');
			return false;
		}

		$range.find('.filter__range-limit-min').html(min.discharge());
		$range.find('.filter__range-limit-mid').html(Math.round((min + max)/2).discharge());
		$range.find('.filter__range-limit-max').html(max.discharge());

		let slider = noUiSlider.create($line[0], {
			start: [min, max],
			connect: true,
			margin: max - min > 10000 ? 1000 : 100,
			range: {
				'min': min,
				'max': max
			}
		}).on('update', function(val){
			$range.find('.filter__range-value-item_min').find('.filter__range-value-input').val(Math.round(val[0]));
			$range.find('.filter__range-value-item_max').find('.filter__range-value-input').val(Math.round(val[1]));
		});

		$range.on('keyup', '.filter__range-value-input', function () {

			let $t = $(this);

			if ( $t.val().match(/[^0-9]/g) ) {
				let _newVal = $t.val().replace(/[^0-9]/g, '') || 0;
				$t.val(_newVal);
			}

		}).on('change', '.filter__range-value-input', function () {
			$line[0].noUiSlider.set([+$range.find('.filter__range-value-item_min').find('.filter__range-value-input').val(), +$range.find('.filter__range-value-item_max').find('.filter__range-value-input').val()]);
		});

	});


	$b
		.on('click', '.counter__handle', function (e) {
			let $t = $(this),
				isMore = $t.hasClass('counter__handle_more'),
				$counter = $t.closest('.counter'),
				$input = $counter.find('.counter__value'),
				val = parseInt($input.val()),
				$product = $t.closest('.js-price-product')
			;

			if ( $product.length ){
				let $priceVal = $product.find('.js-price-sum-val'),
					$saleVal = $product.find('.js-price-sale-val'),
					$priceRes = $product.find('.js-price-sum-res'),
					$saleRes = $product.find('.js-price-sale-res')
				;

				if ( isMore ){
					val++;
				} else {
					val = val - 1 < 0 ? 0 : val - 1;
				}

				$input.val(val);
				$priceRes.html((+$priceVal.html().replace(/\s/ig, '') * val).discharge());
				$saleRes.html((+$saleVal.html().replace(/\s/ig, '') * val).discharge());

				if ( val > 0 ){
					$saleRes.closest('.price').removeClass('is-hidden');
				} else {
					$saleRes.closest('.price').addClass('is-hidden');
				}

			}

		})

		.on('change keyup', '.counter__value', function (e) {

			if ( $(this).val().match(/[^0-9]/g) ) {
				let _newVal = $(this).val().replace(/[^0-9]/g, '') || 0;
				$(this).val(_newVal);
			}

			let $input = $(this),
				val = parseInt($input.val()),
				$product = $input.closest('.js-price-product')
			;

			if ( $product.length ){
				let $priceVal = $product.find('.js-price-sum-val'),
					$saleVal = $product.find('.js-price-sale-val'),
					$priceRes = $product.find('.js-price-sum-res'),
					$saleRes = $product.find('.js-price-sale-res')
				;

				$priceRes.html((+$priceVal.html().replace(/\s/ig, '') * val).discharge());
				$saleRes.html((+$saleVal.html().replace(/\s/ig, '') * val).discharge());

				if ( val > 0 ){
					$saleRes.closest('.price').removeClass('is-hidden');
				} else {
					$saleRes.closest('.price').addClass('is-hidden');
				}

			}

		})
		.on('click', '.js-submit', function (e) {
			console.info($(this).closest('form'));
			$(this).closest('form').submit();
		})
		.on('submit', '.form', function (e) {
			let $form = $(this),
				$item = $form.find('input'),
				wrong = false
			;

			$item.each(function () {
				let $input = $(this), rule = $(this).attr('data-require');

				if ( $input.is(':visible') ){

					$input
						.closest('.form__field')
						.removeClass('f-error f-message')
						.find('.form__message')
						.html('')
					;

					if ( rule ){

						if ( $(rule).length ){

							if ( $input.val() !== $(rule).val() ){
								wrong = true;
								$input
									.closest('.form__field')
									.addClass('f-error f-message')
									.find('.form__message')
									.html('Пароли не совпадают')
							}


						} else {
							form.validate($input[0], rule, err =>{
								if (err.errors.length) {
									wrong = true;
									$input
										.closest('.form__field')
										.addClass('f-error f-message')
										.find('.form__message')
										.html(err.errors[0])
									;
								}
							})
						}

					}
				}

			});

			if ( wrong ){

				$('html,body').animate({scrollTop:$('.f-error').eq(0).offset().top - 20}, 500);
				e.preventDefault();
			}
		})


		// filter

		.on('click', '.js-clear-filter', function (e) {

			$('.filter').find('.filter__field').each(function () {
				let $t = $(this);

				if ( $t.hasClass('filter__field_single')){
					$t.find('input[type=radio]').eq(0).prop('checked', true).trigger('change');
				} else if ( $t.hasClass('filter__field_multiple')){
					$t.find('input[type=checkbox]').prop('checked', false).last().trigger('change');
				} else if ( $t.hasClass('filter__field_range')){
					let min = $t.find('.filter__range').attr('data-value-min'),
						max = $t.find('.filter__range').attr('data-value-max');

					$t.find('.filter__range-value-item_min').find('input').val(min);
					$t.find('.filter__range-value-item_max').find('input').val(max).trigger('change');
				}

			});
		})
		.on('click', function (e) {
			if ( $('.filter__field').filter('.is-open').length && !$(e.target).closest('.filter__field').length ){
				$('.filter__field').filter('.is-open').removeClass('is-open');
			}
			if ( $('.filter__info-sort-value').filter('.is-open').length && !$(e.target).closest('.filter__info-sort-value').length ){
				$('.filter__info-sort-value').filter('.is-open').removeClass('is-open');
			}
			if ( $('.cart__order-city').filter('.is-open').length && !$(e.target).closest('.cart__order-city').length ){
				$('.cart__order-city').filter('.is-open').removeClass('is-open');
			}
		})

		.on('click', '.filter__field-case', function () {
			let $t = $(this),
				$field = $t.closest('.filter__field')
			;
			$('.filter__field').not($field).removeClass('is-open');

			$t.closest('.filter__field').toggleClass('is-open');

		})

		.on('click', '.filter__dropdown-close', function (e) {
			let $t = $(this);
			$t.closest('.filter__field').removeClass('is-open');
		})

		.on('click', '.filter__info-switch', function (e) {
			let $t = $(this);
			$t.closest('.filter').find('.filter__list').toggleClass('is-inline');
		})

		.on('click', '.filter__info-sort-value-item', function (e) {
			let $t = $(this),
				$wrap = $t.closest('.filter__info-sort-value')
			;

			if ( !$wrap.hasClass('is-open') ){
				$wrap.addClass('is-open');
			} else {
				$t.addClass('is-active').siblings().removeClass('is-active');
				$wrap.removeClass('is-open');
			}

		})


		.on('click', '.filter__field-case-handler', function (e) {
			let $t = $(this),
				$field = $t.closest('.filter__field')
			;

			if ($field.hasClass('is-chosen') && $field.hasClass('filter__field_multiple')){
				e.stopPropagation();
				$field.removeClass('is-chosen').find('input').prop('checked', false);
				return false;
			}
		})

		.on('change', '.filter__field_multiple .filter__item-input', function () {
			let $t = $(this),
				$field = $t.closest('.filter__field'),
				$inputChecked = $field.find('input').filter(':checked')
			;
			$field.find('.filter__field-case-sum').html($inputChecked.length);

			if ( $inputChecked.length > 0){
				$field.addClass('is-chosen');
			} else {
				$field.removeClass('is-chosen');
			}
		})

		.on('change', '.filter__field_single .filter__item-input', function () {
			let $t = $(this),
				$item = $t.closest('.filter__item'),
				$field = $t.closest('.filter__field')
			;
			$field.removeClass('is-open').find('.filter__field-case-value').html($item.find('.filter__item-name').text());

			$field.find('.filter__dropdown-search').find('input').val('').trigger('change');
		})

		.on('change keyup', '.filter__dropdown-search input', function () {
			let $input = $(this),
				$wrap = $input.closest('.filter__dropdown'),
				$item = $wrap.find('.filter__item'),
				value = $.trim($input.val().toLowerCase()),
				count = 0,
				$message = $('<div/>', {class: 'filter__item-message', text: 'Нет результатов'})
			;

			$wrap.find('.filter__item-message').remove();

			if ( value.length > 0 ){

				$item.addClass('is-hidden').each(function () {
					let $t = $(this),
						val = $t.find('.filter__item-name').text().toLowerCase()
					;

					if (val.indexOf(value) >= 0){
						$t.removeClass('is-hidden');
					} else {
						count++;
					}

				});

				if ( count === $item.length ){
					$wrap.find('.filter__dropdown-inner').append($message);
				}

			} else {
				$item.removeClass('is-hidden');
			}

		})

		.on('click', '.header__menu-contact-phone', function () {
			let $t = $(this),
				$wrap = $t.closest('.header__menu-contact')
			;

			if ( $wrap.hasClass('is-open') ){
				$('.header__dropdown').parent().removeClass('is-open');
			} else {
				$('.header__dropdown').parent().removeClass('is-open');
				$wrap.addClass('is-open');
			}

		})

		.on('click', '.header__location-value', function () {
			let $t = $(this),
				$wrap = $t.closest('.header__location')
			;

			if ( $wrap.hasClass('is-open') ){
				$('.header__dropdown').parent().removeClass('is-open');
			} else {
				$('.header__dropdown').parent().removeClass('is-open');
				$wrap.addClass('is-open');
			}
		})

		.on('click', '.header__dropdown-close', function () {
			let $t = $(this)
			;
			$t.closest('.is-open').removeClass('is-open');
		})

		.on('change', '[data-radiotab-link]', function () {
			let $t = $(this),
				val = $t.attr('data-radiotab-link'),
				$tabs = $('[data-radiotab-group=' + $t.attr('name') + ']'),
				$tab = $('[data-radiotab-target = ' + val + ']')
			;

			$tabs.removeClass('is-active');
			$tab.filter('[data-radiotab-target = ' + val + ']').addClass('is-active');
		})

		.on('change', '.cart__order-form input[type=file]', function (e) {
			let $t = $(this),
				$wrap = $t.closest('.form__field'),
				file = e.target.files[0],
				$message = $wrap.find('.form__message'),
				errorText = 'Файл не должен превышать 50 мб., формат: doc, docx, pdf, jpeg'
			;

			$wrap.removeClass('f-error f-message');
			$message.html('');

			if ( this.value && !/\.(jpg|jpeg|doc|docx|pdf)$/i.test( this.value ) || file.size > 50000000 ) {
				$wrap.addClass('f-error f-message');
				$message.html(errorText);
			} else if (this.value && window.File && window.FileReader && window.FileList && window.Blob) {
				$wrap.addClass('is-fileload').find('.form__input-file-name').html( file.name );
			}
		})

		.on('click', '.form__input-file-remove', function () {
			let $t = $(this),
				$wrap = $t.closest('.form__field')
			;
			$wrap.removeClass('is-fileload').find('input')[0].value = '';
		})


		.on('click', '.js-goto-welcome-catalog', function (e) {
			$('.welcome__filter').removeClass('is-hidden');
			$('.welcome__search').addClass('is-hidden');
		})

		.on('click', '.popup__product-description-toggle span', function (e) {
			$(this).closest('.popup__product-description').toggleClass('is-open');
		})

		.on('click', '.js-open-callphone', function (e) {
			e.preventDefault();
			$('html,body').animate({scrollTop:0}, 500);
			$('.header__menu-contact-phone').click()
		})

		.on('click', '.sp-question span', function (e) {
			let $t = $(this),
				$wrap = $t.closest('.sp'),
				$answer = $wrap.find('.sp-answer')
			;

			$wrap.addClass('is-loading');
			$answer.slideToggle(300, ()=>{
				$wrap.toggleClass('is-open').removeClass('is-loading');
				$answer.removeAttr('style');
			})

		})
	;



	(function(){

		let $filter = $('.filter');

		if ( $filter.length ){
			let t = null;

			f();

			$(window).on('resize', ()=>{
				if (t) clearTimeout(t);
				t = setTimeout(f, 100);
			});
		}

		function f() {
			let count = 0;

			$filter.find('.filter__field').removeClass('is-outside').each(function () {
				let $t = $(this),
					$inner = $t.closest('.inner')
				;
				if ( $t.position().left >= $inner.width() || $t.position().top >= 1 ){
					$t.addClass('is-outside');
					count++;
				}

			});


			if ( count <=0 ){
				$filter.find('.filter__info-switch').addClass('is-hidden');
			} else {
				$filter.find('.filter__info-switch').removeClass('is-hidden');
			}
		}

	})();






	if ( $('#itemMap').length ){

		let coordinates = $('#itemMap').attr('data-coordinates').split(','), myMap;

		myMap = new Yamap({
			center: coordinates,
			container: '#itemMap',
			controls: [],
			behaviors: ['default'],
			zoom: 15,
			minZoom: 10,
			maxZoom: 20,
			objectManagerOptions: {
				clusterize: false
			},

			onInit: function (map) {
				map.behaviors.disable('scrollZoom');
				map.controls.add('zoomControl', {
					float: 'none',
					position: {
						right: 40,
						top: 20
					}
				});

				let myPlacemark = new ymaps.Placemark(map.getCenter(), {
				}, {
					iconLayout: 'default#image',
					iconImageHref: '/img/baloon.svg',
					iconImageSize: [38, 52],
					iconImageOffset: [-19, -52],
					balloonOffset: [0, 0]
				});

				map.geoObjects.add(myPlacemark)
			}
		});


		$b
			.on('click', '.js-map-route-toggle', function (e) {
				e.preventDefault();
				$(this).closest('.map-route').toggleClass('is-start');
			})
			.on('click', '.js-map-route-scroll', function (e) {
				e.preventDefault();
				$('html,body').animate({scrollTop:$('#itemMap').offset().top - 20}, 500);
			})
			.on('submit', '.js-map-route-form', function (e) {
				e.preventDefault();

				let $form = $(this),
					$result = $form.closest('.map-route').find('.map-route__result'),
					html = '',
					multiRoute = null
				;

				if ( !$form.find('.f-error').length ){

					console.info('1');

					ymaps.geocode($form.find('input').val(), {
						results: 1,
						kind: 'house'
						//json: true
					}).then((res)=>{
						let obj = res.geoObjects.get(0),
							coords = obj.geometry.getCoordinates(),
							data = obj.properties.get('metaDataProperty'),
							address = {},
							first = true
						;

						console.info(res);
						console.info(data);
						console.info(data.GeocoderMetaData.Address.Components);
						console.info(coords);

						html += '<h4>Ваш адрес:</h4>';

						for( let i = 0; i < data.GeocoderMetaData.Address.Components.length; i++ ){
							address[data.GeocoderMetaData.Address.Components[i].kind] = data.GeocoderMetaData.Address.Components[i].name;

							if (
								data.GeocoderMetaData.Address.Components[i].kind === 'locality'
								|| data.GeocoderMetaData.Address.Components[i].kind === 'street'
								|| data.GeocoderMetaData.Address.Components[i].kind === 'house'
							){
								if (!first) html += ', ';
								first = false;

								html += data.GeocoderMetaData.Address.Components[i].name;
							}
						}

						$result.html(html);

						myMap.map.geoObjects.removeAll();

						multiRoute = new ymaps.multiRouter.MultiRoute({
							referencePoints: [$form.find('input').val(), coordinates],
							params: {
								results: 1
							}
						}, {
							boundsAutoApply: true
						});

						myMap.map.geoObjects.add(multiRoute);

						multiRoute.model.events
							.add("requestsuccess", function (event) {
								let routes = event.get("target").getRoutes();

								// console.info(routes[0].properties.get('distance').text);
								// console.info(routes[0].properties.get('duration').text);

								html += `<h4>Длина маршрута:</h4><p>${routes[0].properties.get('distance').text}</p>`;
								html += `<h4>Время в пути:</h4><p>${routes[0].properties.get('duration').text}</p>`;
								html += `<p><span class="btn btn_light js-map-route-scroll">Посмотреть на карте</span></p>`;

								$result.html(html);

							})
							.add("requestfail", function (event) {
								console.log("Ошибка: " + event.get("error").message);
							});

					}, (err)=>{
						console.info(err);
					});



				}



			})
	}

	if ( $('#orderMap').length ){

		let offsetX = 0,
			showInfo = false
		;

		new ViewPort({
			'0': ()=>{
				offsetX = 100;
			},
			'1260': ()=>{
				offsetX = 150;

			},
			'1660': ()=>{
				offsetX = 200;
			}
		});


		let myMap = new Yamap({
			center: [59.939, 30.3158],
			container: '#orderMap',
			controls: [],
			behaviors: ['default'],
			minZoom: 10,
			maxZoom: 20,
			objectManagerOptions: {
				clusterize: false
			},

			onInit: function (map) {
				//map.behaviors.disable('scrollZoom');

				$.getScript($('#orderMap').attr('data-url'), (data)=>{
					myMap.addObjects(JSON.parse(data));
				});

				myMap.objectManager.objects.events
					.add('click', function (e) {
						let id = e.get('objectId');
						getInfo(id);
					})
					// .add('mouseenter', function (e) {
					// 	let id = e.get('objectId');
					// 	console.info('mouseover');
					// })
					// .add('mouseleave', function (e) {
					// 	console.info('mouseleave');
					// })
				 ;



				myMap.objectManager.objects.options.set({
					iconLayout: 'default#image',
					iconImageSize: [38, 52],
					iconImageOffset: [-19, -52],
					balloonOffset: [0, 0]
				});

				myMap.map.setGlobalPixelCenter([ myMap.map.getGlobalPixelCenter()[0] - offsetX, myMap.map.getGlobalPixelCenter()[1] ]);
				getPoints();

				/*ymaps.geocode($('.cart__order-city-value').text()).then(
					res=>{
						let bounds = res.geoObjects.get(0).properties.get('boundedBy'),
							coords = res.geoObjects.get(0).geometry.getCoordinates(),
							pixelCoords = res.geoObjects.get(0).geometry.getCoordinates().toGlobalPixels();
						myMap.map.setBounds(bounds);
						console.info(coords, pixelCoords);
						// myMap.map.setCenter(coords);
						myMap.map.setGlobalPixelCenter([ pixelCoords[0] - 500, pixelCoords[1] ]);
					},
					err =>{
						// обработка ошибки
					}
				)*/
			},

			onBoundsChange: function (map) {
				if (!showInfo) getPoints();
			},

			objectCollectionParser: (obj)=>{
				return {
					geometry: {
						// required
						coordinates: obj.coordinates
					},
					properties: {
						//objectType: obj.type
						shortDescription: obj.shortDescription,
						fullDescription: obj.fullDescription
					},
					options: {
						iconImageHref: '/img/baloon.svg'
					}
				}
			}
		});


		$b
			.on('click', '.cart__order-city-value', function (e) {
				let $t = $(this),
					$wrap = $t.closest('.cart__order-city')
				;

				if ( !$wrap.hasClass('is-open') ){
					$wrap.addClass('is-open');
				}

			})
			.on('click', '.object-point', function (e) {
				let id = $(this).attr('data-id');

				showInfo = true;
				getInfo(id);
				myMap.setCenter(myMap.objectCollection[id].geometry.coordinates, 15);
				myMap.map.setGlobalPixelCenter([ myMap.map.getGlobalPixelCenter()[0] - offsetX, myMap.map.getGlobalPixelCenter()[1] ]);
				showInfo = false;
			})

			.on('click', '.cart__order-city-item', function (e) {
				let $t = $(this),
					$wrap = $t.closest('.cart__order-city')
				;
				$t.addClass('is-active').siblings().removeClass('is-active');
				$wrap.removeClass('is-open').find('.cart__order-city-value').html($t.text());

				ymaps.geocode($t.text()).then(
					res=>{
						let bounds = res.geoObjects.get(0).properties.get('boundedBy'),
							coords = res.geoObjects.get(0).geometry.getCoordinates();

						myMap.map.setBounds(bounds);
						myMap.map.setCenter(coords);
						myMap.map.setGlobalPixelCenter([ myMap.map.getGlobalPixelCenter()[0] - offsetX, myMap.map.getGlobalPixelCenter()[1] ]);
					},
					err =>{
						// обработка ошибки
					}
				)
			})
		;

		let timer = 0;

		function getPoints() {

			if (timer) clearTimeout(timer);
			timer = setTimeout(()=>{

				let i = 0,
					points = myMap.shownObjects(),
					$inner = $('.cart__order-map-info-inner')
				;
				$inner.html('');
				for(i; i < points.length; i++){
					let $point = $(points[i].properties.shortDescription);
					$point.attr('data-id', points[i].id);
					$inner.append($point);
				}

				//window.app.scrollbar.update();

			}, 50);

		}

		function getInfo(id) {


			let $info = $(myMap.objectCollection[id].properties.fullDescription),
				$inner = $('.cart__order-map-info-inner')
			;

			// myMap.objectManager.objects.setObjectOptions(id, {
			//  	iconImageHref: '/img/baloon_hover.svg'
			// });

			$inner.html($info);
		}

	}










	// nav

	(function menu() {

		let
			delay = 300,
			element = '.nav__item',
			navTimerOver = null,
			navTimerLeave = null,
			itemTimerOver = null,
			itemTimerLeave = null
		;

		$b
			.on('mouseover', '.header__menu-nav', function () {
				let $t = $(this);
				clearTimeout(navTimerLeave);
				navTimerOver = setTimeout(()=>{
					$t.addClass('is-active');
				}, delay);
			})

			.on('mouseleave', '.header__menu-nav', function () {
				let $t = $(this);
				clearTimeout(navTimerOver);
				navTimerLeave = setTimeout(()=>{
					$t.removeClass('is-active');
					$(element).removeClass('is-hover');
					navDim(true);
				}, delay);
			})

			.on('mouseover', element, function (e) {
				let $t = $(this);
				if ( $t.parent().closest('.is-hover').length ) e.stopPropagation();
				clearTimeout(itemTimerLeave);
				itemTimerOver = setTimeout(()=>{
					$t.addClass('is-hover').siblings(element).removeClass('is-hover');
					navDim();
				}, delay);
			})

			.on('mouseleave', element, function (e) {
				//e.stopPropagation();
				// clearTimeout(itemTimerLeave);
				clearTimeout(itemTimerOver);
				itemTimerLeave = setTimeout(()=>{
					$(this).removeClass('is-hover');
					navDim();
				}, delay);
			})

		;


		function navDim(clear) {
			let $nav = $('.nav'),
				height = 0,
				width = 0
			;
			$nav.removeAttr('style');

			if (!clear){
				$nav.find('.nav__list').filter(':visible').each(function () {
					let $t = $(this);
					width += $t.outerWidth();
					height = $t.outerHeight() > height ? $t.outerHeight() : height;
				});

				$nav.css({
					width: width,
					height: height
				});
			}

		}

	})();



});
