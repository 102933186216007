import $ from 'jquery'
import Simplebar from 'simplebar'

export default class ScrollBar {
	constructor(options) {

		this._polyfill();
		this._storage = [];

		Object.assign(this._options = {}, this._default(), options);
	}

	_default() {
		return {};
	}

	init(el) {

		let that = this,
			element = el || that._options.customElement
		;

		$(element).each(function () {
			let $t = $(this);

			if (!$t.hasClass('is-inited')){
				$t.addClass('is-inited');
				let scroller = new Simplebar(this, that._options);
				that._storage.push(scroller);
			}

		});

	}

	update(){
		for (let i = 0; i < this._storage.length; i++){
			this._storage[i].recalculate();
		}
	}

	_polyfill() {
		if (!Object.assign) {
			Object.defineProperty(Object, 'assign', {
				enumerable: false,
				configurable: true,
				writable: true,
				value: function (target, firstSource) {
					'use strict';
					if (target === undefined || target === null) {
						throw new TypeError('Cannot convert first argument to object');
					}

					let to = Object(target);
					for (let i = 1; i < arguments.length; i++) {
						let nextSource = arguments[i];
						if (nextSource === undefined || nextSource === null) {
							continue;
						}

						let keysArray = Object.keys(Object(nextSource));
						for (let nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
							let nextKey = keysArray[nextIndex],
								desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
							if (desc !== undefined && desc.enumerable) {
								to[nextKey] = nextSource[nextKey];
							}
						}
					}
					return to;
				}
			});
		}

	}

}
